import { Theme } from "@mui/material";

const getTableStyle = (theme: Theme) => [{
  '& .deleted-row': {
    color: theme.palette.grey[300]
  },
  '& .MuiDataGrid-cell': {
    backgroundColor: theme.palette.common.white,
    border: 0.25,
    borderColor: theme.palette.grey[300],
    '&.changed-cell': {
      position: "relative",
    },
    '&.new-cell': {
      backgroundColor: "#ffffe0"
    },
    '&.readonly-cell': {
      backgroundColor: "#eee"
    },
  },
  '& .changed-cell:before': {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    borderTop: `8px solid ${theme.palette.info.main}`,
    borderLeft: `8px solid ${theme.palette.common.white}`,
    width: 0
  }
}]

export default getTableStyle;