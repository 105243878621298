import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { MaterialUIControllerProvider } from "./context";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./services/azureAuth";
import { CookiesProvider } from "react-cookie";
import "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

const queryClient = new QueryClient()

const container = document.getElementById("root")!;
const root = createRoot(container)

root.render(
  <MsalProvider instance={msalInstance}>
    <CookiesProvider>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </CookiesProvider>
  </MsalProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
