import { CheckCircle, Error, Info, Report } from "@mui/icons-material";
import { InputAdornment, Paper, TextField } from "@mui/material";
import { useCallback } from "react";

export interface ActionResultBoxProps {
  type: "info" | "success" | "error" | "warning" | undefined;
  message: string | undefined;
}

export default function ActionResultBox(props: ActionResultBoxProps) {
  const { type, message } = props;

  const Icon = useCallback(() => {
    switch (type) {
      case "info": return <Info color={type} />
      case "success": return <CheckCircle color={type} />
      case "error": return <Error color={type} />
      case "warning": return <Report color={type} />
      default: return <></>;
    }
  }, [type]);

  return (
    <Paper sx={{ height: "100%", display: "flex", flex: 1, minWidth: 450, alignItems: "center", p: .5, w: "100%" }}>
      <TextField
        value={message}
        fullWidth
        size="small"
        variant="standard"
        
        sx={{
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: 0,
            padding: 0
          }
        }}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          )
        }}
      />
    </Paper>
  )
     
}