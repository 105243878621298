import { useCallback, useMemo, useState } from "react";
import { ContentParams, Filter } from "..";
import { isEmpty } from "lodash";
import { useDataTableModify } from "./useDataTableModify";

export const LIMIT = 100;

const initialContentParams: ContentParams =  {
  rawQuery: undefined,
  clauses: {},
  pagination: {
    offset: 0,
    limit: LIMIT
  },
  orderBy: [{
    field: "Ky",
    sort: "asc"
  }],
};

export const useDataTableData = (tableAccessor?: string) => {
  const [contentParams, setContentParams] = useState<ContentParams>(initialContentParams);
  const [pendingFilter, setPendingFilter] = useState<Filter>({ exactFilter: {}, rawFilter: undefined });
  const [page, setPage] = useState(initialContentParams.pagination.offset);

  const contentRequestAttributes = useMemo(() => {
    const paramsCopy = { ...contentParams };
    delete paramsCopy.orderBy;
    const contentOrderBy = contentParams?.orderBy;
    const attributes = {
      ...paramsCopy,
      ...{
        orderBy: {
          field: isEmpty(contentOrderBy) ? initialContentParams!.orderBy![0].field : contentOrderBy![0].field,
          sort: isEmpty(contentOrderBy) ? initialContentParams!.orderBy![0].sort : contentOrderBy![0].sort,
        }
      },
    };
    return attributes;
  }, [contentParams, initialContentParams]);


  const {
    tableRows,
    canSave,
    isSavingInProgress,
    tableContent,
    tableContentError,
    canEdit,
    getOriginalCellValue,
    getIsDeletedRow,
    getIsNewRow,
    getIsChangedValue,
    handleClearTable,
    handleRowUpdate,
    handleAddNewRow,
    handleRemoveDataRow,
    handleSaveTableData,
    handleFocusCell,
    undoDeleteRemoteDataRow,
    refetchTableContent,
  } = useDataTableModify(tableAccessor, contentRequestAttributes)

  const handleCleanParams = useCallback(() => {
    setPendingFilter({ exactFilter: {}, rawFilter: undefined });
    setContentParams(initialContentParams);
  }, []);

  const tableName = useMemo(() => {
    if (!!tableContent) {
      const { folderName, name } = tableContent || {};
      return `${folderName || ''}${folderName ? ' / ' : ''}${name}`;
    }
  }, [tableContent]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setContentParams({
      ...contentParams,
      pagination: {
        offset: newPage * LIMIT,
        limit: LIMIT
      }
    });
  }

  const handleUpdateContentParams = useCallback((newParams: any) => {
    setContentParams(current => ({
      ...current,
      ...newParams
    }));

    refetchTableContent({
      ...contentParams,
      ...newParams
    });
  }, [refetchTableContent, contentParams])
  
  const handleSetPendingFilter = (filter: Filter) => {
    setPendingFilter(filter);
  }

  return {
    page,
    canEdit,
    canSave,
    isSavingInProgress,
    tableName,
    tableRows,
    tableContent,
    tableContentError,
    pendingFilter,
    orderBy: contentParams.orderBy,
    contentParams,
    getOriginalCellValue,
    getIsDeletedRow,
    getIsNewRow,
    getIsChangedValue,
    handlePageChange,
    handleCleanParams,
    handleClearTable,
    handleRowUpdate,
    handleAddNewRow,
    handleRemoveDataRow,
    handleSaveTableData,
    handleUpdateContentParams,
    handleFocusCell,
    handleSetPendingFilter,
    undoDeleteRemoteDataRow,
  }
}