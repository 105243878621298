import { Save, FilterAlt, ChevronRightOutlined, Restore } from "@mui/icons-material";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { map, some } from "lodash";
import { useCallback } from "react";
import { lightTheme } from "../../assets/theme";
import { FilterContent } from "../../hooks/azureTableHooks";
import ActionResultBox, { ActionResultBoxProps } from "../DataTable/ActionBox";
import { useTranslation } from "react-i18next";

interface FormToolbarProps {
  action?: ActionResultBoxProps;
  canSave: boolean;
  formName?: string;
  filters?: FilterContent[];

  onFilterOpen: () => void;
  onRestore: () => void;
  onSave: () => Promise<void>;
  onAddNewRow: () => void;
}

export const FormToolbar = (props: FormToolbarProps) => {

  const { action, canSave, formName, filters, onFilterOpen, onRestore, onSave, onAddNewRow } = props;

  const { t } = useTranslation("translations");

  const FilterBreadCrumbs = useCallback(() => {
    if (some(filters, f => !!f.selected)) {
      return (
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack display="flex" direction="row" alignItems="center">
            {map(filters, (f, idx) => {
              const name = f.options.find(o => o.value.toString() === f.selected?.toString())?.name;
              if (!name) return null;
              return <Stack key={`crumb_${idx}`} display="flex" direction="row" alignItems="center">
                {idx !== 0 && <ChevronRightOutlined fontSize="small" color="disabled" />}
                <Chip label={name} size="small" />
              </Stack>
            })}
          </Stack>
          <Stack>
            <Button
              startIcon={<FilterAlt />}
              onClick={onFilterOpen}>
              {t("filter_header")}
            </Button>
          </Stack>
        </Stack>
      )
      
    }
    return null;
  }, [filters]);


  return (
    <Box>
      <Box px={2} py={.5} borderBottom={1} borderColor={lightTheme.palette.grey[400]}
        display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography>{formName}</Typography>
        </Box>
        <Box>
          <ActionResultBox type={action?.type} message={action?.message} />
        </Box>
        <Stack direction="row">
          {/* <Button
            key="toolbar_add_btn"
            disabled={filters?.length === 0}
            onClick={onAddNewRow}
            startIcon={<Add />}
          >
            {t("datatable.actions.add")}
          </Button> */}
          <Button
            key="toolbar_save_btn"
            disabled={!canSave}
            startIcon={<Save />}
            onClick={onSave}>
            {t("datatable.actions.save")}
          </Button>
          <Button
            key="toolbar_restore_btn"
            disabled={!canSave}
            startIcon={<Restore />}
            onClick={onRestore}>
              {t("datatable.actions.restore")}
          </Button>
        </Stack>
      </Box>
      <Box px={2} py={1}>
        <FilterBreadCrumbs />
      </Box>
    </Box>
  )
}