import fetcher from "../services/fetchJson";
import { HELP_URL } from "../services/serverUrl";
import { useQuery } from "react-query";
import { getCookie } from "cookies-next";

interface HelpItem {
  title: string;
  url: string;
}

export function useHelp() {
  const isAuthenticated = getCookie('adpt-auth-token');

  const { data: helpItems } =
    useQuery<HelpItem[]>([`help`],
      () => fetcher(HELP_URL, {
        method: "GET"
      }),
       {
      enabled: !!isAuthenticated,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    });

  return { helpItems }
}