import { GridRowId, GridSortModel } from "@mui/x-data-grid";
import { ConfirmDialogProps } from "../Dialog";
import { DataTable } from "./DataTable";

export interface ChangedRowUnit {
  rowId: GridRowId;
  Ky?: GridRowId;
  originalRow?: { [key: string]: any; };
  currentRow?: { [key: string]: any; };
  changedFields?: string[];
  isNewRow?: boolean;
}

export interface ValueUnit {
  [column: string]: any;
}

export interface DataTableProps {
  tableAccessor?: string;
  setDialogData: (data?: ConfirmDialogProps) => void;
  type?: "FORM" | "TABLE";
}

export interface ContentParams {
  rawQuery: string | undefined;
  clauses: {};
  pagination: {
    offset: number;
    limit: number;
  }
  orderBy?: GridSortModel;
  filterContent?: {
    title: string;
    key: string;
    selected: string;
    options: {
      value: string;
      name: string;
    }[]
  }[];
}

export type Anchor = "bottom" | "right";
export type Filter = { exactFilter: {}, rawFilter: string | undefined };

export default DataTable;
