import { Box, IconButton, Typography } from "@mui/material";
import DrawerContainer from "../DrawerContainer";
import { Close, Refresh } from "@mui/icons-material";
import { FilterForm } from "./FilterForm";
import { FilterParams } from "../../hooks/azureTableHooks";
import { useTranslation } from "react-i18next";

interface DrawerProps {
  columns: any;
  currentFilter: FilterParams;
  visibleDrawer: "right" | "bottom" | undefined;
  toggleDrawer: (anchor: "right" | "bottom", open: boolean) => void;
  bottomDrawerTitle?: string;
  bottomDrawerComponent?: React.ReactNode;
  onFilterChange: (filter: any) => void;
  onContentParamsChange: (contentParams: any) => void;
  onActionResultChange: (actionResult: any) => void;
}

const Drawer = (props: DrawerProps) => {
  const {
    columns,
    currentFilter,
    visibleDrawer,
    toggleDrawer,
    bottomDrawerTitle,
    bottomDrawerComponent,
    onFilterChange,
    onContentParamsChange,
    onActionResultChange,
  } = props;

  const { t } = useTranslation("translations");

  const handleCloseDrawer = () => {
    toggleDrawer(visibleDrawer!, false);
  }

  const handleFilterReset = () => {
    onFilterChange({ exactFilter: {} })
  }

  const handleFilterFormHide = (filter: FilterParams) => {
    onFilterChange({ exactFilter: filter })
  }

  const handleFilterSubmit = (filter: FilterParams) => {
    handleFilterFormHide(filter)
    onContentParamsChange({ clauses: filter, rawQuery: undefined } );
    handleCloseDrawer()
    onActionResultChange({ type: "info", message: t("datatable.actions.filtered") });
  }
  
  return (
    <>
      <DrawerContainer
        open={visibleDrawer === "right"}
        anchor={"right"}
        justifyHeaderContent="space-between"
        headerComponents={[
          <Typography key="right_drawer_title">{t("filter")}</Typography>,
          <Box key="right_drawer_buttons">
            <IconButton
              aria-label="restore"
              onClick={handleFilterReset}
              key="drawer_component_restore"
            >
              <Refresh />
            </IconButton>
            <IconButton
              aria-label="close"
              onClick={handleCloseDrawer}
            >
              <Close />
            </IconButton>
          </Box>
        ]}
        onClose={handleCloseDrawer}
        width={{
          xs: "100%",
          sm: "55%",
          md: "40%",
          lg: "30%"
        }}
      >
        <FilterForm
          filter={currentFilter}
          columns={columns}
          hideForm={!visibleDrawer}
          onFormHide={handleFilterFormHide}
          onSubmit={handleFilterSubmit} />
      </DrawerContainer>
      <DrawerContainer
        open={visibleDrawer === "bottom"}
        anchor={"bottom"}
        justifyHeaderContent="space-between"
        headerComponents={[
          <Typography key="bottom_drawer_title">
            {bottomDrawerTitle}
          </Typography>,
          <Box key="bottom_drawer_buttons">
            <IconButton
              aria-label="close"
              onClick={handleCloseDrawer}
            >
              <Close />
            </IconButton>
          </Box>
        ]}
        onClose={handleCloseDrawer}>
        {bottomDrawerComponent}
      </DrawerContainer>
    </>
  )
}

export default Drawer;