import { GridSingleSelectColDef, GridRenderCellParams, getGridDefaultColumnTypes, DEFAULT_GRID_COL_TYPE_KEY } from "@mui/x-data-grid";
import _, { includes } from "lodash";
import { ContentColumn } from "../../../hooks/azureTableHooks";

const defaultColumnTypes = getGridDefaultColumnTypes();

const availableFilterOperators = [
  "equals", "startsWith", "endsWith", "contains", ">", "<", ">=", "<=", "=", "!=", "before", "after", "is", "isAnyOf"
]

export default function getDataColumnContent(c: ContentColumn): GridSingleSelectColDef {
  const filterOperators = defaultColumnTypes[c.dataType ?? DEFAULT_GRID_COL_TYPE_KEY].filterOperators;

  const filters = filterOperators?.filter(o => includes(availableFilterOperators, o?.value)) ?? [];

  return {
    field: c.accessor,
    headerName: c.name,
    type: c.dataType as any,
    editable: !c.readonly,
    flex: c.width ? undefined : 1,
    valueOptions: c.valueOptions?.map(o => {
      return {
        value: o.value,
        label: o.label
      }
    }),
    valueGetter: (value) => {
      if (c.dataType === "dateTime") {
        if (!value) return undefined;
        return new Date(value as string);
      }
      return value;
    },

    width: c.width,
    cellClassName: c.readonly ? "readonly-cell" : "",
    filterOperators: filters,
    filterable: filters.length > 0,
    renderCell: (params: GridRenderCellParams) => { 
      if(c.accessor === "Ky" && !_.isNumber(params?.value)) return <span>#</span>
    },
  }
}