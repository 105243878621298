import { useCallback, useEffect, useMemo, useState } from "react";
import { ChangedRowUnit, ValueUnit } from "..";
import { ContentRequestAttributes, YesOrNo, useTableContent } from "../../../hooks/azureTableHooks";
import { filter, find, get, isEmpty, isNumber, keyBy, map, reduce, reject } from "lodash";
import { GridCellParams, GridRowId } from "@mui/x-data-grid";
import { v4 as uuid } from 'uuid';
import { postContent } from "../../../services/requests/azureTable";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";

// Utils
const isNewRow = (rowId: GridRowId) => !isNumber(rowId);

const isDeletedRow = (rowId: GridRowId, changedRows: any) => {
  const changedRow = find(changedRows, { rowId });
  if (!changedRow) return false;
  return !changedRow.currentRow;
}

const isChangedValue = (rowId: GridRowId, field: string, changedRows: any) => {
  const changedRow = find(changedRows, { rowId });
  if (!changedRow?.originalRow || !changedRow?.currentRow) return false;
  return changedRow.originalRow[field] !== changedRow.currentRow[field];
};

export const useDataTableModify = (tableAccessor?: string, body?: ContentRequestAttributes) => {
  const [changedRows, setChangedRows] = useState<ChangedRowUnit[]>([]);
  const [isSavingInProgress, setSavingInProgress] = useState(false);
  const [cellIsFocused, setCellIsFocused] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const queryClient = useQueryClient()

  const { tableContent, refetchTableContent, tableContentLoading, canEdit, tableContentError } = useTableContent(tableAccessor, body);

  const handleClearTable = useCallback(() => {
    setChangedRows([]);
  }, [setChangedRows]);

  const getDefaultSelectValues = useCallback(() => {
    let defaultValues = {};
    tableContent?.columns.forEach(c => {
      if (c.dataType === "singleSelect") {
        const defaultValue = c.valueOptions.find(v => v.value === -1) ?? c.valueOptions[0];
        defaultValues = {
          ...defaultValues,
          ...{ [c.accessor]: defaultValue.value }
        };
      }
    });
    return defaultValues;
  }, [tableContent?.columns]);

  const tableRows = useMemo(() => {
    const newRows = filter(changedRows, "isNewRow")?.map((row) => row.currentRow) as ValueUnit[];
    if (!tableContent?.rows && !newRows.length) return [] as ValueUnit[];

    const rows = reduce(tableContent?.rows, (result, value) => {
      const changedValue = get(find(changedRows, { rowId: value.Ky }), 'currentRow', {});
      return [
        ...result,
        {
          ...value,
          ...changedValue,
        }
      ];
    }, [] as ValueUnit[]);
    return [...newRows, ...rows] as ValueUnit[];
  }, [changedRows, tableContent?.rows]);

  const location = useLocation();

  useEffect(() => {
    const rowsChanged = !isEmpty(changedRows);
    setCanSave(!cellIsFocused && rowsChanged);
    window.history.pushState("", "", location.pathname + (rowsChanged ? "/#!" : ""));
  }, [cellIsFocused, changedRows, location.pathname]);

  const handleAddNewRow = useCallback(
    (duplicateId?: GridRowId, callback?: () => void) => {
      
      const newRowId = uuid();
      const originRow = duplicateId ? find(tableRows, { Ky: duplicateId }) : {};

      let newRow = {
        ...originRow,
        Ky: newRowId,
        ...(duplicateId ? {} : getDefaultSelectValues())
      };

      setChangedRows((previousValues) => [
       {
          rowId: newRowId,
          currentRow: newRow,
          isNewRow: true,
       },
        ...previousValues,
      ])
      
      callback && callback()
    },
    [tableRows, getDefaultSelectValues]
  );

  const handleRowUpdate = (updatedRow: any, originalRow: any) => {
    const rowId = updatedRow.Ky;

    const rowToUpdate = {
      rowId,
      isNewRow: isNewRow(rowId),
      originalRow: originalRow,
      currentRow: updatedRow,
    }

    setChangedRows((previousValues) => {
      const updatedRowIndex = previousValues.findIndex((row) => row.rowId === rowId);
      if (updatedRowIndex > -1) {
        previousValues[updatedRowIndex] = rowToUpdate;
      } else {
        previousValues.push(rowToUpdate);
      }
      return [...previousValues];
    });

    return updatedRow;
  };

  const handleRemoveDataRow = useCallback((rowId: GridRowId) => {
    if (isNewRow(rowId)) {
      // timeout need to set focus off the row before removing the row from table
      setTimeout(() => {
        setChangedRows((previousRows) => reject(previousRows, { rowId }));
      });
    } else {
      setChangedRows((previousValues) => [...previousValues, { rowId }]);
    }
  }, []);

  const undoDeleteRemoteDataRow = useCallback((rowId: GridRowId) => {
    setChangedRows((previousValues) => reject(previousValues, { rowId }));
  }, []);

  const handleSaveTableData = async (callback?: (success?: boolean) => void) => {
    setSavingInProgress(true);

    const dataToSave = map(changedRows, (row) => {
      if (row.isNewRow)  {
       const {
          rowId,
          Ky,
        ...newRow
       } = row?.currentRow || {} as ValueUnit;

        return newRow;
      }

      if (row.currentRow) {
        // Map undefined keys to null
        return reduce(row.currentRow, (acc, value, key) => {
          if (value === undefined) {
            acc[key] = null;
          } else {
            acc[key] = value;
          }
          return acc
        }, {} as ValueUnit)
      }
      
      const deletedRow = find(tableRows, { Ky: row.rowId })!;
      return { ...deletedRow, ...{ RwIsCrrnt: YesOrNo.No } };
    });

    try {
      await postContent(tableAccessor || "", dataToSave);
      await queryClient.invalidateQueries(["tableContent", tableAccessor]);
      callback && callback(true);
      setTimeout(handleClearTable, 1000)
    } catch (e: any) {
      callback && callback(false);
    } finally {
      setSavingInProgress(false);
    }
  };

  const handleFocusCell = useCallback((isFocused?: boolean) => {
    setCellIsFocused(isFocused || false);
  }, [])

  const getOriginalCellValue = (params: GridCellParams) => {
    const originalRow = find(changedRows, r => r.rowId === params.id)?.originalRow;
    const originalValue = originalRow && originalRow[params.field] !== params.value
      ? ` (${originalRow[params.field]})`
      : "";

    return originalValue;
  }

  const getIsDeletedRow = (rowId: GridRowId) => isDeletedRow(rowId, changedRows);
  const getIsNewRow = (rowId: GridRowId) => isNewRow(rowId);
  const getIsChangedValue = (rowId: GridRowId, field: string) => isChangedValue(rowId, field, changedRows);

  return {
    tableContent,
    tableRows,
    changedRows,
    canSave,
    isSavingInProgress,
    isTableContentLoading: tableContentLoading,
    canEdit,
    tableContentError,
    refetchTableContent,
    undoDeleteRemoteDataRow,
    getOriginalCellValue,
    getIsDeletedRow,
    getIsNewRow,
    getIsChangedValue,
    handleClearTable,
    handleRowUpdate,
    handleAddNewRow,
    handleRemoveDataRow,
    handleSaveTableData,
    handleFocusCell,
  }
}