// export const msalConfig = {
//   auth: {
//     clientId: "cb33c9ec-19d5-40bd-85b1-8712f902d6ef",
//     authority: "https://login.microsoftonline.com/770526a0-de5b-421f-85a5-aca56d64c888", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//     redirectUri: "https://arendus.andmeladu.ee", // This is the URL where the user will be redirected after they sign in
//   },
//   cache: {
//     claimsBasedCachingEnabled: true, // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/v2-migration.md#claims-based-caching
//     cacheLocation: "sessionStorage", // This configures where your cache will be stored
//     storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//   }
// };

// Production
export const msalConfig = {
  auth: {
    clientId: "229cfc85-32bf-41a6-bba6-fd6ba0d61cb5",
    authority: "https://login.microsoftonline.com/f01725fb-e05a-415f-9d95-39ad70ae07ae", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://arendus.andmeladu.ee", // This is the URL where the user will be redirected after they sign in
  },
  cache: {
    claimsBasedCachingEnabled: true, // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/v2-migration.md#claims-based-caching
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};