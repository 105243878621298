import useSWR from "swr";
import fetcher from "../services/fetchJson";
import { USER_SESSION_URL, USER_URL, MESSAGES_URL } from "../services/serverUrl";
import { groupBy } from "lodash";
import { useQuery } from "react-query";
import { getCookie } from "cookies-next";

export interface User {
  username: string;
  language: string;
  groups?: string[];
  isMdmAdmin: boolean;
}

export interface Database {
  name: string;
  displayName: string;
  serverId: string;
  view: string;
  isActive: boolean;
}

export interface Session {
  username: string;
  groups?: string[];
  serverId?: string;
  database?: string;
  view?: string;
  language?: string;
}

export interface Message {
  key: number;
  group: "VIEW" | "COMPANY";
  description: string;
  url: string;
  date: string;
}

export interface UserInfo {
  user: User;
  databases: Database[];
}

export function useUserInfoByUsername(username?: string) {
  const isAuthenticated = !!getCookie('adpt-auth-token');
  const { data: userInfo, refetch: mutateUserInfo } = useQuery<UserInfo>(
    ["userInfo", username],
    () => fetcher(`${USER_URL}?username=${username}`, {
      method: "GET"
    }),
    {
      enabled: isAuthenticated && !!username,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return { user: userInfo?.user, databases: userInfo?.databases, mutateUserInfo };
}

export function useSession(forceAuth = false) {
  const isAuthenticated = forceAuth || !!getCookie('adpt-auth-token');
  const { data: session, refetch: mutateSession } = useQuery<Session>(
    ["userSession"],
    () => fetcher(USER_SESSION_URL, {
      method: "GET"
    }),
    {
      enabled: isAuthenticated,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  return { session, mutateSession };
}

export function useMessages() {
  const { data: messages, mutate: mutateMessages } = useSWR<Message[]>(MESSAGES_URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { groupedMessages: groupBy(messages, (m) => m.group), mutateMessages };
}