import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import Dialog, { ConfirmDialogProps } from "../../components/Dialog";
import FormTable from "../../components/FormTable";
import { TTableType } from "../../hooks/azureTableHooks";
import DataTable from "../../components/DataTable";

interface DataTableCardProps {
  type: TTableType;
}

function DataTableCard(props: DataTableCardProps) {

  const { type } = props;

  const { tableAccessor } = useParams();
  const [dialog, setDialog] = useState<ConfirmDialogProps>();

  const setDialogData = useCallback((data?: ConfirmDialogProps) => {
    if (data) {
      setDialog({
        ...data,
        handleClose: () => {
          data?.handleClose && data.handleClose();
          setDialog(undefined)
        },
        handleConfirm: () => {
          data?.handleConfirm && data.handleConfirm();
          setDialog(undefined)
        },
      })
    } else {
      setDialog(undefined)
    }

  }, [])

  return (
    <>
      {type === "TABLE"
        ? <DataTable tableAccessor={tableAccessor} setDialogData={setDialogData} />
        : <FormTable tableAccessor={tableAccessor} setDialogData={setDialogData} />
      }
      <Dialog {...dialog} />
    </>
  );
}

export default DataTableCard;
