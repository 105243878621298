import { useCallback, useEffect, useMemo, useState } from "react";
import { some, map, get, reduce, isNumber, uniqueId } from "lodash";
import { Button, Card, Stack, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { ConfirmDialogProps } from "../Dialog";
import { FilterForm } from "./FilterForm";
import { CellChange, DateCell, NumberCell, TextCell } from "@silevis/reactgrid";
import { ActionResultBoxProps } from "../DataTable/ActionBox";
import "@silevis/reactgrid/styles.css";
import { TableSettings, useTableSettings } from "../../hooks/tableSettingsHook";
import { DataRow, FilterContent as IFilterContent, YesOrNo } from "../../hooks/azureTableHooks";
import { postFormContent } from "../../services/requests/azureTable";
import { TFormFilter, useFormContent } from "../../hooks/planningFormHooks";
import { FormToolbar } from "./FormToolbar";
import { FilterDrawer } from "./FormFilterDrawer";
import DataGrid from "../DataGrid";
import getTableStyle from "../DataTable/Styles.ts";
import { lightTheme } from "../../assets/theme";
import { GridCellParams, GridColumnResizeParams, GridRowId, GridValidRowModel } from "@mui/x-data-grid";
import { LIMIT } from "../DataTable/hooks/useDataTableData";
import { FilterAlt } from "@mui/icons-material";


interface DataTableProps {
  tableAccessor?: string;
  setDialogData: (data?: ConfirmDialogProps) => void;
}

function FormTable(props: DataTableProps) {

  const { tableAccessor, setDialogData } = props;

  const location = useLocation();
  const { t } = useTranslation("translations");

  const [changedRows, setChangedRows] = useState<DataRow[]>([]);
  const [formFilter, setFormFilter] = useState<TFormFilter>({});
  const [drawerOpen, setFilterFormOpen] = useState(false);
  const [actionResult, setActionResult] = useState<ActionResultBoxProps>();
  const [canSave, setCanSave] = useState(false);

  const { canSubmitForm, filterContent, isLoading, rows, columns, title, queryCompleted, getOriginalRow, updateFormContent }
    = useFormContent(tableAccessor, formFilter);
  const { getTableSettings, setColumnWidth } = useTableSettings(tableAccessor);

  const [tableSettings, setTableSettings] = useState<TableSettings>(getTableSettings());
  const [currentFilterContent, setCurrentFilterContent] = useState<IFilterContent[]>();
  const [formName, setFormName] = useState(title);


  useEffect(() => {
    const hasRowsChanged = changedRows.length > 0;
    setCanSave(hasRowsChanged);
    window.history.pushState("", "", location.pathname + (hasRowsChanged ? "/#!" : ""));
  }, [changedRows, location.pathname])


  // Table actions
  const clearTable = useCallback(() => {
    setChangedRows([]);
    updateFormContent();
  }, [updateFormContent])


  useEffect(() => {
    clearTable();
    setFormFilter({});
  }, [tableAccessor, clearTable]);


  useEffect(() => {
    if (some(filterContent)) setCurrentFilterContent(filterContent);
  }, [filterContent]);


  useEffect(() => {
    if (!!title) setFormName(title);
  }, [title]);

  const isNewRow = (rowId: GridRowId) => !isNumber(rowId);
  const isChangedValue = (rowId: GridRowId, field: string, changedRows: any) => {
    const changedRow = changedRows.find((r: DataRow) => r.Ky === rowId);
    if (!changedRow) return false;
    return changedRow[field] !== getOriginalRow(rowId)[field];
  };

  const getIsNewRow = (rowId: GridRowId) => isNewRow(rowId);
  const getIsChangedValue = (rowId: GridRowId, field: string) => isChangedValue(rowId, field, changedRows);


  const getFormColumns = useCallback(() => {
    if (!queryCompleted) return [];
    return columns.map(c => {
      const width = get(tableSettings.columns, c.accessor)?.width ?? c.width;
      return {
        field: c.accessor,
        headerName: c.name,
        type: c.dataType as any,
        editable: !c.readonly,
        cellClassName: (params: GridCellParams) => getCellClassName(params, c),
        flex: width ? undefined : 1,
        valueOptions: c.valueOptions?.map(o => {
          return {
            value: o.value,
            label: o.label
          }
        }),
        width,
        columnId: c.accessor,
        resizable: true,
        
      }
    }) ?? [];
  }, [columns, tableSettings, queryCompleted]);

  const formRows = useMemo(() => {
    return map(rows, (row, idx) => {
      const changedRow = changedRows.find(r => r.Ky === row.Ky);

      return {
        ...row,
        ...changedRow
      }
    })
  }, [rows, changedRows]);

  const pinnedColumns = useMemo(() => {
    return columns.filter(c => c.readonly).map(c => c.accessor);
  }, [columns]);

  const getCellValue = (c: CellChange, prevValue: boolean = false) => {
    const cell = prevValue ? c.previousCell : c.newCell;

    if (c.type === "number") {
      const value = (cell as NumberCell).value;
      return isNaN(value) ? null : value;
    } else if (c.type === "date") {
      return (cell as DateCell).date ?? null;
    } else {
      return (cell as TextCell).text ?? null;
    }
  }

  const handleRowUpdate = useCallback((newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
    const rowId = newRow.Ky;
    setChangedRows(prev => {
      const previousChangedRows = prev.filter(r => r.Ky !== rowId);
      
      return [
        ...previousChangedRows,
        newRow,
      ]
    })
    return newRow
  }, [rows]);

  const handleUpdateColumnWidth = (params: GridColumnResizeParams) => {
    const updatedSettings = setColumnWidth(params.colDef.field, params.width);
    setTableSettings(updatedSettings);
  }


  const getCellClassName = (params: GridCellParams, column?: any) => {
    if (column?.readonly) return "readonly-cell";
    if (getIsNewRow(params.id)) return "new-cell";
    return getIsChangedValue(params.id, params.field) ? "changed-cell" : "";
  };


  const toggleFilterOpen = useCallback(() => {
    setFilterFormOpen(prev => !prev);
  }, []);


  const handleRestore = useCallback(() => {
    clearTable();
    setActionResult({ type: "info", message: t("planning_form.actions.restored") });
  }, [clearTable]);

  const handleAddNewRow = useCallback(() => {
    setChangedRows(prev => {
      const newRow = {
        Ky: uniqueId("new_"),
        RwIsCrrnt: YesOrNo.Yes,
      } as DataRow;
      return [...prev, newRow];
    });
  }, []);


  const handleSave = useCallback(async () => {
    const valuesToSave = changedRows.map(row => {
      const originalRow = getOriginalRow(row.Ky);
      const updatedObject = reduce(row, (acc, value, key) => {
        acc[key] = originalRow[key];
        if (value !== originalRow[key]) {
          acc[`${key}_new`] = row[key];
        }
        return acc;
      }, {} as DataRow);
      return updatedObject;
    });

    const res: { success: boolean } = await postFormContent(tableAccessor, valuesToSave);

    if (res.success) {
      await updateFormContent();
      clearTable();
      setActionResult({ type: "success", message: t("planning_form.actions.saved") });
    } else {
      setActionResult({ type: "error", message: t("planning_form.actions.failed") });
    }
  }, [tableAccessor, changedRows]);


  const handleFilter = useCallback(() => {
    if (canSubmitForm) {
      setFormFilter(prev => ({ ...prev, submit_form: 1 }))
      setFilterFormOpen(false);
    }
  }, [canSubmitForm]);


  const Toolbar = useCallback(() => {
    return (
      <FormToolbar
        action={actionResult}
        canSave={canSave}
        formName={formName}
        filters={queryCompleted ? currentFilterContent : undefined}
        onFilterOpen={toggleFilterOpen}
        onRestore={handleRestore}
        onSave={handleSave}
        onAddNewRow={handleAddNewRow}  
      />
    )
  }, [actionResult, canSave, formName, currentFilterContent, queryCompleted, toggleFilterOpen, handleRestore, handleSave]);

  const NoRowsOverlay = useCallback(() => {

    if (drawerOpen || isLoading) return null;

    if (!canSubmitForm) {
      return (
        <Stack justifyContent="center" alignItems="center">
          <Typography p={2} align="center">{t("planning_form.missing_filter_values")}</Typography>
          <Button onClick={toggleFilterOpen} startIcon={<FilterAlt />}>
            {t("filter_header")}
          </Button>
        </Stack>
      )
    }

    return <Typography p={2} align="center">{t("planning_form.no_rows")}</Typography>
  }, [formFilter, drawerOpen, isLoading]);


  const FilterContent = useCallback(() => {
    if (!currentFilterContent) return null;
    return (
      <FilterForm
        filterContent={currentFilterContent} 
        onChange={setFormFilter}
        canSubmit={canSubmitForm}
        onFilterClick={handleFilter} />
    )
  }, [currentFilterContent]);

  return (
    <Card sx={{ height: 'calc(100vh - 42px)' }}>
      <DataGrid
        sx={getTableStyle(lightTheme)}
        columns={getFormColumns()}
        rows={formRows}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={(error) => console.error(error)}
        slots={{
          toolbar: Toolbar,
          noRowsOverlay: NoRowsOverlay
        }}
        density="compact"
        disableRowSelectionOnClick
        ignoreValueFormatterDuringExport
        cellSelection
        getCellClassName={getCellClassName}
        getRowId={r => r.Ky}
        onColumnWidthChange={handleUpdateColumnWidth}
        disableColumnPinning
        disableRowGrouping
        disableAggregation
        pinnedColumns={{ left: pinnedColumns }}
        // getRowClassName={(params) => getIsDeletedRow(params.row.Ky) ? "deleted-row" : ""}
        // loading={isSavingInProgress || (!tableContent && !tableContentError)}
        // isCellEditable={(params: GridCellParams) => canEdit && !getIsDeletedRow(params.id)}
        // onSortModelChange={handleSortingModeChange}
        // pagination
        // paginationMode="server"
        // paginationModel={{ page, pageSize: LIMIT }}
        // paginationMeta={{ hasNextPage: (tableContent?.rowsCount || 0) > page * LIMIT + LIMIT }}
        // pageSizeOptions={[50, 100, tableContent?.rowsCount ?? 0]}
        // onPaginationModelChange={(paginationModel) => {
        //   handlePageChange(paginationModel.page);
        // }}
        // onCellEditStart={(params: GridCellParams) => handleFocusCell(!params.colDef.editable || !canEdit)}
        // onCellEditStop={() => handleFocusCell(false)}
        // onCellClick={handleCellClick}
        // onCellDoubleClick={handleCellDoubleClick}
        sortingMode={rows.length >= LIMIT ? "server" : "client"}
        // sortModel={orderBy}
        // rowCount={tableContent?.rowsCount ?? 0}
        rowHeight={36} />
        <FilterDrawer open={drawerOpen} closeDrawer={toggleFilterOpen} >
          <FilterContent />
        </FilterDrawer>
    </Card>
  )

  // return (
  //   <>
  //     <Toolbar />
  //     {isEmpty(rows)
  //       ? <MissingFilterValueMessage />
  //       : <ReactGrid
  //         columns={getFormColumns()}
  //         rows={[headerRow, ...formRows]}
  //         enableFillHandle={true}
  //         stickyTopRows={1}
  //         stickyLeftColumns={1}
  //         onCellsChanged={handleValueChanges}
  //         onColumnResized={handleColumnResize}
  //       />}
  //     <FilterDrawer open={drawerOpen} closeDrawer={toggleFilterOpen} >
  //       <FilterContent />
  //     </FilterDrawer>
  //   </>
  // );
}

export default FormTable;
