import { Box, Grid, TextField } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid";
import textAreaStyle from "./Styles.ts/monospaceTextArea";

interface editFormParams {
  cellParams: GridCellParams
  onChange: (cellParams: GridCellParams) => void;
}

export function CellEditForm(editFormParams: editFormParams) {

  const cellParams = editFormParams.cellParams;

  return (
    <Box p={1} fontFamily="monospace">
      <Grid container>
        <Grid container item direction="column" spacing={1}>
          <Grid item>
            <TextField
              multiline
              rows={14}
              fullWidth
              defaultValue={cellParams.value}
              InputProps={textAreaStyle()}
              onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) => {
                cellParams.value = e.target.value;
                editFormParams.onChange(cellParams);
              }}
              sx={{ whiteSpace: "nowrap" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
