import { styled } from "@mui/material/styles";
import { DataGridPremium } from '@mui/x-data-grid-premium';

const DataGrid = styled(DataGridPremium)(({ theme }) => ({
  '& .MuiDataGrid-toolbarContainer': {
    padding: 1,
    borderBottom: '1px solid ' + theme.palette.grey[300],
    justifyContent: 'space-between'
    
  },
  '& .MuiTablePagination-actions .MuiButtonBase-root': {
      padding: 0,
      margin: 10
    }
}))

export default DataGrid;