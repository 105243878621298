import { IconButtonProps, Stack } from "@mui/material";
import { GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { ReactElement } from "react";
import ActionResultBox, { ActionResultBoxProps } from "./ActionBox";

interface CustomToolbarProps {
  actionResult?: ActionResultBoxProps;
  buttons: ReactElement<IconButtonProps>[];
};

export default function TableToolbar(props: CustomToolbarProps) {
  const { actionResult, buttons, ...rest } = props;
  const { type: action, message: content } = actionResult ?? {};

  return (
    <GridToolbarContainer>
      <Stack direction="row" flex={1} justifyContent="space-between" p={1}>
        <Stack direction="row" spacing={1}>
          <GridToolbarExport />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </Stack>
        <Stack direction="row" flex={1} px={24} >
          <ActionResultBox type={action} message={content} />
        </Stack>
        <Stack direction="row" spacing={1}>
          {buttons}
        </Stack>
      </Stack>
    </GridToolbarContainer>
  )
}
