import { ContentCopy, Delete, PushPin } from "@mui/icons-material";
import { GridActionsCellItem, GridColDef, GridRowId, GridRowParams } from "@mui/x-data-grid";
import { DataRow } from "../../../hooks/azureTableHooks";

const iconStyle = {
  height: 15
}

interface ActionColumnContentProps {
  canDelete: boolean;
  pinnedRows?: GridRowId[];
  deleteClick: (id: GridRowId) => void;
  duplicateClick: (id: GridRowId) => void;
  onPinClick: (id: GridRowId) => void;
}

export default function ActionColumnContent(props: ActionColumnContentProps): GridColDef {
  const { canDelete, deleteClick, duplicateClick, onPinClick } = props;

  return {
    field: "actions",
    type: "actions",
    headerName: "",
    width: 120,
    // cellClassName: "actions",
    getActions: ({ id }: GridRowParams<DataRow>) => {
      const actions = [
        <GridActionsCellItem
          icon={<ContentCopy sx={iconStyle} />}
          label="Duplicate"
          color="inherit"
          onClick={() => duplicateClick(id)}
        />];

      if (canDelete) {
        actions.push(
          <GridActionsCellItem
            icon={<Delete sx={iconStyle} />}
            label="Delete"
            color="inherit"
            onClick={() => deleteClick(id)}
          />)
      }

      const isPinned = props.pinnedRows?.includes(id);

      actions.push(
        <GridActionsCellItem
          icon={<PushPin sx={iconStyle} color={isPinned ? "primary" : undefined} />}
          label="Pin to top"
          color="inherit"
          onClick={() => onPinClick(id)}
        />
      )

      return actions;
    }
  }
}