import { Box, Menu, MenuItem } from "@mui/material";
import { isEmpty, map } from "lodash";
import { useCallback, useMemo } from "react";
import { useHelp } from "../../hooks/helpHooks";
import { Link } from "../Link";


interface HelpMenuProps {
  isOpen: boolean;
  anchorEl?: Element | (() => Element) | null;
  onClose: () => void;
}

interface ItemProps {
  title: string;
  url: string;
}

export const HelpMenu = (props: HelpMenuProps) => {

  const { anchorEl, isOpen, onClose } = props;

  const { helpItems } = useHelp();

  const Item = useCallback((props: ItemProps) => {
    const { title, url } = props;

    return (
      <MenuItem sx={{ padding: 0, minWidth: 200 }}>
        <Link url={url} title={title} />
      </MenuItem>);
  }, []);


  const items = useMemo(() => {
    if (isEmpty(helpItems)) return null;

    return map(helpItems, (help, index) => <Item key={`help_${index}`} title={help.title} url={help.url} />)

  }, [helpItems]);

  return (
    <Menu
      id="help-menu"
      anchorEl={anchorEl}
      keepMounted
      open={isOpen}
      onClose={onClose}
    >
      {items}
    </Menu>
  )
}